import {mapActions, mapGetters, mapMutations} from "vuex";
import {validationMixin} from 'vuelidate'
import {maxLength, minLength, required, email} from "vuelidate/lib/validators";

export default {
  name: "login",
  mixins: [validationMixin],
  components:{
  },
  data(){
    return{
      see: true,
      payload: {
        login: '',
        password: '',
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      login: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(4),
      },
      password: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(8),
      },
    },
  },
  created() {

  },
  computed:{
    ...mapGetters({
      loadingLogin: 'auth/loadingSingIn'
    }),
    passwordError() {
      const error = []
      if (!this.$v.payload.password.$dirty) {
        return error
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.password.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 8))
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    loginError() {
      const error = []
      if (!this.$v.payload.login.$dirty) {
        return error
      }
      if (!this.$v.payload.login.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.login.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.login.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 4))
      }
      if (this.validationErrors.login) {
        this.validationErrors.login.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
  },

  methods:{
    ...mapActions({
      login: `auth/GET_TOKEN`,
    }),
    ...mapMutations({
    }),
    submitForm() {
      this.$v.$touch();
      if (!this.$v.payload.$invalid) {
        this.login(this.payload).then(() => {
          this.$toasted.success(this.$t('success'))
          this.$router.push({name: 'profile'}).catch(() => {
          })
        }).catch(error => {
          if (error.status === 422) {
            this.$toasted.error(error.data.message);
            const errors = error.data.errors;
            for (const i in errors) {
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
          if (error.status === 418) {
            this.$toasted.error(error.data.message);
            const errors = error.data.errors;
            for (const i in errors) {
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
        })
      }
    }
  }
}